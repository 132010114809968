.rj-prepacksboard {
  height: 100%;
}
.rj-prepacks {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #121212;
  list-style: none;
}
.rj-prepacks-filter {
  box-sizing: border-box;
  padding: 7px 16px;
  border: 1px solid #242424;
  border-radius: 50px;
  color: #ababab;
  background-color: #121212;
  white-space: nowrap;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 300ms ease;
}
.rj-prepacks-filter[aria-selected="true"] {
  border-color: #fff;
  color: #fff;
}
button.rj-prepacks-filter {
  font-family: 'Saira Condensed', sans-serif;
}
.rj-prepacks-filter-team-logo {
  display: inline-block;
  margin: 0 8px -3px 0;
}
.rj-prepacks-filters {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: #121212;
  z-index: 2;
}
.rj-prepacks-filters::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}
.rj-prepacks-filters-inner {
  display: flex;
  gap: 8px;
  padding: 8px 16px 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.rj-prepack {
  position: relative;
  padding: 16px;
  list-style: none;
}
.rj-prepack::after {
  content: '';
  position: absolute;
  right: 16px;
  bottom: 0;
  left: 16px;
  height: 1px;
  background-color: #242424;
}
sb-lazy-render:last-child .rj-prepack:last-child::after {
  display: none;
}
.rj-prepack__selections {
  position: relative;
  margin-bottom: 16px;
}
.rj-prepack__toggle {
  width: 100%;
  height: 52px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  background-color: #242424;
  color: #53D337;
  border: 0;
  border-radius: 4px;
  z-index: 0;
  position: relative;
}
.rj-prepack__toggle[aria-pressed="true"] {
  background-color: #fff;
  color: #000;
}
.rj-prepack__toggle-odds {
  position: relative;
}
.rj-prepack__toggle--loading {
  position: relative;
  overflow: hidden;
  color: #000;
  background-color: #fff;
}
.rj-prepack__toggle--locked,
.rj-prepack__toggle--suspended {
  position: relative;
  overflow: hidden;
  cursor: default;
  color: #fff;
}
.rj-prepack__toggle--suspended {
  opacity: 0.6;
}
.rj-prepack__toggle-animate-loading {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #DADADA;
  animation: rj-prepack__toggle-animate-loading 1.6s ease-in infinite;
}
@keyframes rj-prepack__toggle-animate-loading {
  0% {
    transform: translate(-100%);
  }
  100% {
    transform: translate(100%);
  }
}
.rj-prepack__selection {
  position: relative;
  display: flex;
  align-items: baseline;
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.rj-prepack__selection:not(:last-child) {
  margin-bottom: 4px;
}
.rj-prepack__selection:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 12px;
  bottom: -6px;
  left: 6px;
  border-left: 1px dashed #4d4d4d;
}
.rj-prepack__selection-indicator {
  position: absolute;
  top: 4px;
  box-sizing: border-box;
  flex-shrink: 0;
  width: 13px;
  height: 13px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #121212;
  z-index: 1;
}
.rj-prepack__selection-name {
  color: #fff;
  flex-shrink: 0;
}
.rj-prepack__market-name {
  color: #ababab;
}
.rj-prepack__market-name::before {
  content: '';
  display: inline-block;
  margin: 0 6px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #ababab;
  vertical-align: middle;
}
.rj-prepack__selection-name-wrapper {
  margin-left: 20px;
}
.rj-prepacks-no-bets {
  position: absolute;
  margin: auto 40px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.rj-prepacks-no-bets__title {
  margin: 8px 0;
  color: #fff;
  font-family: 'Saira Condensed', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  text-transform: uppercase;
}
.rj-prepacks-no-bets__info {
  margin: 0;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.rj-prepacks-no-bets__button {
  margin-top: 32px;
  padding: 10px 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  background-color: #53D337;
  color: #121212;
  border: 0;
  border-radius: 4px;
}
.rj-prepacks-loading-placeholder__component {
  padding: 0 16px 16px 16px;
}
.rj-prepacks-loading-placeholder__component:not(:last-child):after {
  content: "";
  display: block;
  margin-top: 17px;
  height: 1px;
  background-color: #373737;
  animation: rj-prepacks-loading-placeholder 1.2s infinite;
}
.rj-prepacks-loading-placeholder__button {
  height: 52px;
  margin-top: 22px;
  border-radius: 4px;
  background-color: #373737;
  animation: rj-prepacks-loading-placeholder 1200ms infinite;
}
.rj-prepacks-loading-placeholder__rect {
  height: 9px;
  margin-top: 15px;
  border-radius: 2px;
  background-color: #373737;
  animation: rj-prepacks-loading-placeholder 1200ms infinite;
}
.rj-prepacks-loading-placeholder__rect:nth-child(3n - 2) {
  width: 213px;
}
.rj-prepacks-loading-placeholder__rect:nth-child(2) {
  width: 200px;
}
.rj-prepacks-loading-placeholder__rect:nth-child(3) {
  width: 161px;
}
@keyframes rj-prepacks-loading-placeholder {
  0% {
    opacity: 30%;
  }
  50% {
    opacity: 70%;
  }
  100% {
    opacity: 30%;
  }
}
.rj-prepack-odds-update-indicator {
  pointer-events: none;
  animation: rj-flash-outcome-cell-background 750ms linear;
}
.rj-prepack-odds-update-indicator .rj-prepacks-odds-update-arrow {
  position: absolute;
  top: 2px;
  right: 2px;
}
.rj-prepack-odds-update-indicator .rj-prepacks-odds-update-arrow--up {
  animation: rj-flash-outcome-cell-background 750ms linear both;
}
.rj-prepack-odds-update-indicator .rj-prepacks-odds-update-arrow--down {
  animation: rj-flash-outcome-cell-background 750ms linear both;
}
sb-lazy-render {
  display: block;
  position: relative;
  box-sizing: border-box;
}
@keyframes rj-flash-outcome-cell-background {
  0% {
    background-color: #242424;
  }
  50% {
    background-color: #ababab;
  }
  100% {
    background-color: #242424;
  }
}
